import React from 'react';
import {Rnd} from 'react-rnd';
import {Card, Button} from 'react-bootstrap';
import './dialog.css';
import { useState } from 'react';
const dialog_style = {
    zIndex: 999,
    display: 'flex'
}


const Dialog = props => {
    const [width, setWidth] = useState(920);
    const [height, setHeight] = useState(500);
    const screenWidth = window.screen.width;
    const [x, setX] = useState(() => calculateInitialX());
    const [y, setY] = useState(() => calculateInitialY());
    function calculateInitialX() {
        if (screenWidth < 1440 && screenWidth > 1024) {
          setWidth(800);
          return 280; 
        }
        else if(screenWidth < 1180){
            setWidth(700);
          return 250; 
        } else {
          return 400; 
        }
      }
      function calculateInitialY() {
        if (screenWidth < 1440) {
          return 166; 
        } else {
          return 150; 
        }
      }
    
    return (
            <Rnd
                id={`contrib-dialog-${props.id}`}
                className={"contrib-dialog"}
                style={{...dialog_style, ...props.style}}
                size={{ width, height }}
                position={{ x, y }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setWidth(ref.style.width);
                    setHeight(ref.style.height);
                  }}
                  onDragStop={(e, d) => {
                    setX(d.x);
                    setY(d.y);
                  }}
                minWidth={300}
                minHeight={400}
                onClick={props.onClick}
                onMouseDown={props.onMouseDown}
                dragHandleClassName='draggable-handle'
                bounds='window'
            >
                <Card className='dlg-card'>
                    <Card.Header className='dlg-card-header py-0' style={{alignItems: 'center', display: 'flex'}}>
                    <div className='draggable-handle'>
                        <span>{!props.editable ? 'Note' : props.title}</span>
                        <div className='draggable-space'></div>
                    </div>
                        <Button onClick={props.onClose} variant='link' size='sm' style={{marginLeft:'auto'}}>x</Button>
                    </Card.Header>
                    <Card.Body className='dlg-card-body' style={{position: 'relative'}}>
                        {props.children}
                    </Card.Body>
                    <Card.Footer className="dlg-card-footer py-0">
                        {!props.editable ? null :
                          <Button size='sm' className="dlg-button"
                              onClick={props.onConfirm}
                              disabled={false ||!props.editable}
                          >
                              Contribute
                          </Button>
                        }
                        {props.buildon ?
                         <Button size='sm' className="dlg-button"
                                 onClick={props.onBuildOnClick}>
                             Build-on
                         </Button>
                        : ''}
                    </Card.Footer>
                </Card>
            </Rnd>
    )
}

export default Dialog;
