import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const loggedIn = useSelector(state=> state.globals.isAuthenticated);
    return (<Route {...rest} render={(props) => (
       loggedIn ?
        <Component {...props} />
        : <Redirect to='/' />
    )} />)
}
export default ProtectedRoute;
